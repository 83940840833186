'use client';

import Icon from '@/assets/icons/empty-search.svg';
import { SectionHero } from '@/components/common/section-hero';
import { HeaderMain } from '@/components/header/header-main';
import { buttonVariants } from '@/components/ui/button';
import { HeroWrapper } from '@/features/static-builder/components/hero/hero-wrapper';
import { getLocaleForPathname, getPathname } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import * as Sentry from '@sentry/nextjs';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useEffect } from 'react';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const t = useTranslations();
  const locale = getLocaleForPathname();
  const pathname = getPathname({
    href: { pathname: '/diets/[[...slug]]', params: { slug: [] } },
    locale,
  });

  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
    console.error(error);
  }, [error]);

  return (
    <>
      <HeaderMain />
      <main className='min-h-[calc(100vh-8rem)] pb-8 pt-20 md:pt-(--content-top-padding)'>
        <HeroWrapper className='md:-mb-17 md:pb-17' innerClasses='md:pb-0'>
          <div className='relative z-10'>
            <h2 className='pb-8 text-4xl text-white md:text-5xl'>
              {t('common.oopsie')}
            </h2>
          </div>
        </HeroWrapper>
        <div className='container relative h-full max-md:px-0'>
          <SectionHero>
            <div className='grid h-full place-items-center gap-8 py-16'>
              <Icon className='h-auto w-auto md:h-48 md:w-48' />
              <div className='text-center'>
                <h2 className='mb-4 text-2xl font-bold'>
                  {t('common.somethingWentWrong')}...
                </h2>
                <Link
                  href={pathname}
                  className={cn(
                    buttonVariants({ variant: 'secondary', size: 'lg' }),
                  )}
                >
                  <span>{t('cart.configuration.goBack')}</span>
                  <IconArrowNarrowRight className='ml-2 h-4 w-4' />
                </Link>
              </div>
            </div>
          </SectionHero>
        </div>
      </main>
    </>
  );
}
