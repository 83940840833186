import { cn } from '@/lib/utils';
import { type HTMLAttributes } from 'react';

type HeroWrapperProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  innerClasses?: string;
};

export const HeroWrapper = ({
  children,
  className,
  innerClasses = '',
  ...props
}: HeroWrapperProps) => {
  return (
    <section
      data-pw
      data-hero
      className={cn(
        'after:bg-secondary -mt-18 pt-18 relative z-0 h-full w-screen after:absolute after:right-0 after:top-0 after:block after:h-full after:w-1/2 md:-mt-24 md:pt-24',
        className,
      )}
      {...props}
    >
      {/* Background circle with hidden overflow */}
      <div className='bg-brand-green2 absolute left-0 top-0 h-full w-full overflow-hidden'>
        <div className='container relative z-11 h-full w-full'>
          <div className='bg-brand-green2 absolute -top-[380px] right-11 z-5 h-[1415px] w-[1415px] rounded-full xl:right-[290px]'></div>
        </div>
      </div>
      {/* End Background circle with hidden overflow */}

      <div className='container relative z-11'>
        <div className={cn('', innerClasses)}>{children}</div>
      </div>
    </section>
  );
};
